import { create } from "zustand";
import {
  AuthGetUser,
  AuthLogin,
  AuthLogout,
  AuthRegister,
  AuthRefresh,
  AuthPayment,
  AuthCancelSubscription,
  AuthUpdateUser,
} from "../types";
import {
  getRequest,
  getUser,
  patchRequest,
  postRequest,
  removeAllUserData,
  setAccessToken,
  setRefreshToken,
  setUser,
  setUserId,
} from "../utils";
import { toast } from "react-toastify";

type AuthState = {
  user: any;

  login: AuthLogin;
  logout: AuthLogout;
  getUser: AuthGetUser;
  register: AuthRegister;
  refresh: AuthRefresh;
  payment: AuthPayment;
  cancelSubscription: AuthCancelSubscription;
  updateUser: AuthUpdateUser;
};

export const useAuthStore = create<AuthState>((set) => ({
  user: null,

  register: async (userDetails) => {
    await postRequest({
      url: "/auth/register",
      data: userDetails,
    }).then(({ accessToken, refreshToken, data }) => {
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      setUserId(data.id);
      setUser(data);

      toast.success("Registered, move to login page");
      set({
        user: data,
      });
    });
  },

  login: async (email, password) => {
    await postRequest({
      url: "/auth/login",
      data: { email, password },
    }).then(({ accessToken, refreshToken, data }) => {
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      setUserId(data.id);
      setUser(data);

      set((state) => ({
        user: { ...state.user, ...data },
      }));
    });
  },

  getUser: async (userId: string) => {
    await getRequest({
      url: `/users/${userId}`,
    }).then(({ data }) => {
      set({
        user: data,
      });
    });
  },

  payment: async (payType) => {
    const user = getUser();

    await postRequest({
      url: "/stripe/create-subscription",
      data: { lookupKey: payType, email: user.email },
    }).then(({ redirectUrl }) => {
      window.location.href = redirectUrl;
    });
  },

  refresh: async (refreshToken) => {
    await postRequest({
      url: "/auth/refresh",
      data: { refreshToken },
    }).then(({ accessToken, refreshToken, data }) => {
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      setUser(data);
      set({
        user: data,
      });
    });
  },

  cancelSubscription: async (id) => {
    await patchRequest({
      url: "/stripe/cancel-subscription",
      data: { id },
    }).then((data) => {
      console.log(data);
    });
  },

  updateUser: async (id, updates) => {
    await patchRequest({ url: `users/${id}`, data: updates }).then(
      ({ data }) => {
        setUser(data);
        toast.success("Updated");
      }
    );
  },

  logout: () => {
    removeAllUserData();

    set({
      user: null,
    });
  },
}));
